.centered-title-wrapper {
    padding: 150px 0 50px 0;
    display: flex;
    justify-content: center;
    color: white;
}

.steps {
    padding: 3% 3% 5% 3%;
    display: flex;
    justify-content: space-around;
}

.step {
    min-width: 15%;
}

.how-to-BG {
    background: #4256B3;
}

.how-to-top {
    position: relative;
    margin-top: 80px;
    bottom: -7px;
}

.stars-BG {
    width: 101%;
}

.moon-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    bottom: 280px;
    left: 130px;
}
.moon {
    max-width: 20%;
    margin-right: 3%;
}


@media only screen and (max-width: 1000px) {
    .steps { 
        padding: 10%;
        flex-wrap: wrap;
    }
    .step {
        margin: 20px;
    }
    .moon-wrapper {
        left: 70px;
        bottom: 180px;
    }
    .moon {
        max-width: 30%;
    }
}


@media only screen and (max-width: 675px) {
    .step {
        width: 95%;
    }
    .moon-wrapper {
        bottom: 130px;
    }
    .moon {
        max-width: 40%;
    }
}