main {
  display: flex;
  flex-flow: column;
}

.Nav-and-Hero-wrapper {
  display: flex;
  flex-flow: column;
}

.sun {
  z-index: -1;
  display: flex;
  height: 1300px;
  width: 1300px;
  background-color: #FAC02E;
  right: -750px;
  top: -650px;
  margin-right: 10%;
  border-radius: 50%;
  position: absolute;
}

.how-to-wrapper {
  width: 100%;
  padding: 0 5px 0 5px;
}

.how-to-top {
  width: 101%;
}

.moon {
  min-height: 30px;
  position: relative;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 1000px) {
  .sun {
    height: 1200px;
    width: 1000px;
    margin-right: 20%;
    background-color: yellow;
  }
}

@media only screen and (max-width: 675px) {
  .sun {
    background-color: #FAC02E;
    height: 740px;
    width: 740px;
    top: 320px;
    right: -450px;
  }
}