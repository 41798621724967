.hero-section {
    padding-top: 60px;
    display: flex;
}

.left-side {
    display: flex;
    flex-flow: column;
    flex: 1;
    justify-content: center;
}

.left-text-wrapper {
    width: 80%;
    margin: 0 auto;
}
.mailing-list-form {
    display: flex;
}
.input {
    display: flex;
    flex: 1;
    border: 2px solid #F1F1F1;
    color: #565866;
    padding: 7px;
    border-radius: 7px;
    font-size: .9em;
    margin-right: 10px;
}
::placeholder{
    color: darkgrey;
}

.form-button {
    background-color: #4256B3;
    border-radius: 7px;
    color: white;
    font-size: 1em;
    font-weight: 700;
    justify-content: center;
    padding: 20px;
}
.form-button:focus {
    outline: none;
}
.form-button:hover {
    cursor: pointer;
}

.right-side {
    display: flex;
    flex: 1;
    justify-content: center;
}
.mockup {
    height: 570px;
}

.subtitle {
    font-size: 1.25em;
    font-weight: 700;
    margin-bottom: 60px;
}


/*** Media Queries ***/
@media only screen and (max-width: 1000px) {
    .mockup {
        height: 500px;
    }

    .form-button {
        font-size: .9em;
        padding: 0 5px;
    }

    .left-side {
        flex: 5;
    }

    .right-side {
        flex: 4;
    }
}

@media only screen and (max-width: 675px) {
    .mockup {
        height: inherit;
        width: 82%;
    }

    .mailing-list-form {
        flex-flow: column;
    }

    .input {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .form-button {
        padding: 10px;
        border: none;
    }

    .left-side h1, p {
        text-align: center;
    }

    .subtitle {
        margin-bottom: 0;
    }

    .right-side {
        margin-top: 50px;
    }

    .hero-section {
        flex-flow: column;
    }

    .dont-show-on-phone {
        display: none;
    }
}