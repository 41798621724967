.bottom-form-wrapper {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  maxWidth: 500px;
  margin-bottom: 200px;
  text-align: center;
}

.bottom-form-title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 90%;
}

/*** Media Queries ***/
@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 675px) {
  .bottom-form-wrapper {
    width: 80%;
  }
}