footer {
  width: 100%;
}

.footer-top {
  width: 100%;
  position: relative;
  bottom: -8px;
}

.far-away{
  position: absolute; 
  left: -5000px;
}

.footer-wrapper {
  background: #E77329;
  color: white;
  padding: 50px;
  display: flex;
}

.footer-content {
  width: 100%;
  display: flex;
  padding: 5px;
}

.footer-links {
  display: flex;
  flex: 3;
}

.right-footer {
  flex-direction: column;
}

.link {
  cursor: pointer;
  margin-right: 5vw;
  text-align: center;
}

.social-media-wrapper {
  display: flex;
  flex: 2;
  justify-content: flex-end;
  align-items: center;
}

.social-media-link {
  height: 30px;
  margin-left: 30px;
  cursor: pointer;
}
.instagram-icon {
  height: 38px;
}


@media only screen and (max-width: 1000px) {
  .footer-links {
    flex: 7;
    justify-content: space-between;
  }
  .footer-wrapper {
    padding: 30px;
  }
  .link {
    cursor: pointer;
  }
  .social-media-wrapper {
    flex: 3;
  }
}

@media only screen and (max-width: 675px) {
  .footer-top {
    bottom: -6px;
  }
  .footer-wrapper {
    padding: 20px 0 50px 0;
    justify-content: center;
  }
  
  .footer-content {
    width: 80%;
  }

  .footer-links {
    flex-direction: column;
    align-items: flex-start;
    width: 20px;
  }
  
  .social-media-wrapper {
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
  }
  .social-media-link {
    height: 25px;
  }
  .instagram-icon {
    height: 32px;
  }
  
}